export * from './error.js';
export * from './backends/port/fs.js';
export * from './backends/fetch.js';
export * from './backends/memory.js';
export * from './backends/index/fs.js';
export * from './backends/locked.js';
export * from './backends/overlay.js';
export * from './backends/store/fs.js';
export * from './backends/store/simple.js';
export * from './backends/store/store.js';
export * from './backends/backend.js';
export * from './config.js';
export * from './cred.js';
export * from './file.js';
export * from './filesystem.js';
export * from './inode.js';
export * from './mutex.js';
export * from './stats.js';
export * from './utils.js';

export * from './emulation/index.js';
import * as fs from './emulation/index.js';
export { fs };
export default fs;
